/* eslint-disable @next/next/no-img-element */
"use client"

import {
  ArtworkAuction,
  Erc721TokenExtended,
  ArtworkShowcaseSales,
} from "./base"
import useArtwork from "./useArtwork"
import { getMuxUrl } from "@/utils/mux"
import { AvatarProfile } from "@/ui/avatarProfile"
import { cn } from "@/utils/cn"
import Link from "next/link"
import { chainIdToSlug } from "@/utils/chains"
import { CHAIN_ID } from "@/constants/environment"
import { generateImgixUrl } from "@/utils/imgix"

const ShowcaseCard = ({
  artwork,
  detailsPosition = "default",
}: {
  artwork: Erc721TokenExtended
  detailsPosition?: "default" | "bottom"
  totalArtworks?: number
}): JSX.Element => {
  const { isAuction, isRunningAuction } = useArtwork(artwork)
  const imgSrc =
    artwork.erc721_metadata_media?.uri || artwork.nft_image?.image_full
  return (
    <article
      className={cn(
        `
          group flex w-[90vw] flex-col justify-center gap-3
          lg:flex-row lg:gap-4
          md:w-auto
        `,
        detailsPosition === "bottom" && "!flex-col"
      )}
    >
      <div
        className={cn(
          `
            overflow-hidden group/hero-image mx-auto flex h-auto max-h-[490px] max-w-[440px] rounded-md
            lg:mx-0 lg:max-h-[620px] lg:max-w-[620px]
            md:h-[620px] md:max-h-[620px] md:w-[620px] md:max-w-[620px]
          `,
          detailsPosition === "bottom" &&
            `
              h-[490px]
              md:w-full md:max-w-[620px]
            `
        )}
      >
        {artwork.nft_image?.video_clipped_mux_playback_id ? (
          <Link
            className="m-auto"
            href={`/artwork/${chainIdToSlug(CHAIN_ID) || "eth"}/${artwork.contract_address}/${artwork.token_id}`}
          >
            <video
              src={getMuxUrl(
                artwork.nft_image?.video_clipped_mux_playback_id,
                "medium"
              )}
              className="lg:max-h-[680px] lg:w-full lg:max-w-[680px]"
              autoPlay
              loop
              muted
              playsInline
            ></video>
          </Link>
        ) : (
          <Link
            href={`/artwork/${chainIdToSlug(CHAIN_ID) || "eth"}/${artwork.contract_address}/${artwork.token_id}`}
          >
            <img
              src={
                imgSrc
                  ? generateImgixUrl(imgSrc, {
                      width: "4800",
                      height: null,
                      fm: "avif",
                      blur: null,
                      fit: "clip",
                      quality: "100",
                      video: false,
                      name: "homepage_hero",
                      auto: "compress",
                    })
                  : undefined
              }
              className={cn(
                `
                  mx-auto h-full w-auto w-full rounded-md object-contain duration-300
                  group-hover/hero-image:scale-105
                `,
                detailsPosition === "bottom" && "md:mx-auto"
              )}
              alt=""
            />
          </Link>
        )}
      </div>

      <div
        className={cn(
          `
            w-full max-w-[89vw] overflow-hidden
            lg:max-w-[40vw]
            xl:max-w-[680px]
          `,
          detailsPosition === "default" &&
            `
              mt-auto
              lg:w-96
            `
        )}
      >
        <p className="flex items-center gap-2 text-lg">
          <Link
            className="hover:underline"
            href={`/artwork/${chainIdToSlug(CHAIN_ID) || "eth"}/${artwork.contract_address}/${artwork.token_id}`}
          >
            {artwork?.name}
          </Link>
        </p>
        <div className="py-3">
          {artwork.erc721_creator?.creator && (
            <AvatarProfile size="sm" user={artwork.erc721_creator.creator} />
          )}
        </div>
        <div
          className={`
            border-t border-sr-border-tertiary
            lg:-mb-3
          `}
        >
          {isAuction && isRunningAuction ? (
            <ArtworkAuction
              artwork={artwork}
              kind="showcase"
              auction={isAuction}
            />
          ) : (
            <ArtworkShowcaseSales artwork={artwork} />
          )}
        </div>
      </div>
    </article>
  )
}

export default ShowcaseCard
