"use client"

import Hero from "./(homepage)/hero"
import CuratedConversations from "./(homepage)/curated-conversations"
import FeaturedArtworks from "./(homepage)/featured-artworks"
import LiveAuctions from "./(homepage)/live-auctions"
import StatsHelp from "./(homepage)/stats-help"
import TrendingSeries from "./(homepage)/trending-series"
import TrendingTables from "./(homepage)/trending-tables"
import EditorialFeatures from "./(homepage)/editorial-features"
import CustomSection from "./(homepage)/custom-section"
import NoticeBar from "./(homepage)/notice-bar"

export default function Home(): JSX.Element {
  return (
    <>
      <NoticeBar />
      <main
        className={`
          container mt-8 flex flex-col gap-8
          lg:gap-16
        `}
      >
        <Hero />
        <FeaturedArtworks />
        <TrendingTables />
        <LiveAuctions />
        <CustomSection />
        <TrendingSeries />
        <EditorialFeatures />
        <CuratedConversations />
        <StatsHelp />
      </main>
    </>
  )
}
