"use client"

import { User } from "@/graphql/generated/apollo/graphql"
import { useFollowers } from "@/hooks/useFollowers"
import { useMuting } from "@/hooks/useMuting"
import { useModal } from "@/stores/infoModal"
import { useProfileStore } from "@/stores/profile"
import { Avatar } from "@/ui/avatar"
import { Button } from "@/ui/button"
import { Dialog } from "@/ui/dialog"
import { DropdownMenu } from "@/ui/dropdown"
import { EthAddress } from "@/ui/ethaddress"
import { Loader } from "@/ui/loader"
import { useConnectModal } from "@rainbow-me/rainbowkit"
import makeBlockie from "ethereum-blockies-base64"
import { motion } from "framer-motion"
import { useRouter } from "next/navigation"
import { useAccount } from "wagmi"

interface ProfileUserProps {
  user: User
}

export const FollowOrUnfollowButton = ({
  user,
  kind = "primary",
}: {
  user: User
  kind?: "primary" | "secondary"
}): JSX.Element => {
  const { isConnected } = useAccount()
  const { profile: account } = useProfileStore()
  const { setType, setIsOpen } = useModal()
  const { isLoading, isFollowing, isMutating, handleClickFollow } =
    useFollowers(user)

  if (isLoading)
    return (
      <motion.div
        initial={{ y: 15, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -15, opacity: 0 }}
        key="loading"
        className="flex w-32 items-center justify-center rounded-md border border-sr-border-secondary"
      >
        <Loader kind="simple" className="size-3" />
      </motion.div>
    )
  return (
    <motion.div
      initial={{ y: 15, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -15, opacity: 0 }}
      key={`post-loading`}
    >
      <Button
        kind={kind}
        className="!w-32"
        key={`${isFollowing}-user`}
        loading={isMutating}
        onClick={() => {
          if (!isConnected) {
            setType("connect-wallet")
            setIsOpen(true)
            return
          }
          if (!account?.username) {
            setType("complete-profile")
            setIsOpen(true)
            return
          }
          handleClickFollow(!isFollowing)
        }}
      >
        {isFollowing ? "Following" : "Follow"}
      </Button>
    </motion.div>
  )
}

export const ProfileUser = ({ user }: ProfileUserProps): JSX.Element => {
  const {
    isMuted,
    isMutating,
    handleClickMute,
    showMutingWarning,
    toggleMutingWarning,
  } = useMuting(user)

  const { isConnected } = useAccount()
  const { profile: account } = useProfileStore()
  const { openConnectModal: showDialog } = useConnectModal()
  const router = useRouter()
  return (
    <>
      <Dialog open={showMutingWarning} onOpenChange={toggleMutingWarning}>
        <Dialog.Content title="User Muted" size="xs">
          {!isConnected ? (
            <>
              <p>You need to connect your wallet</p>
              <Button
                className="mt-4 w-full"
                uppercase
                kind="primary"
                onClick={showDialog}
              >
                Connect Wallet
              </Button>
            </>
          ) : !account?.username ? (
            <>
              <p className="my-4">
                To “mute” and make the most of SuperRare complete your profile.
              </p>
              <Button
                className="mt-10 w-full"
                uppercase
                kind="primary"
                onClick={() => router.push("/account/edit-profile")}
              >
                Complete profile
              </Button>
            </>
          ) : (
            <>
              <p className="my-4">
                You will no longer see any of @{user.username} artwork in your
                feed. These changes will take effect after hitting refresh.
              </p>
              <p className="mb-8">
                {`To unmute you can return to this menu and choose "unmute", or head
            over to your SuperRare `}
                <a
                  className="underline"
                  href={`https://superrare.com/settings/${account?.username}`}
                >
                  account settings
                </a>
                {` to manage.`}
              </p>
              <Button
                className="w-full"
                uppercase
                kind="primary"
                onClick={handleClickMute}
                loading={isMutating}
              >
                Got it
              </Button>
            </>
          )}
        </Dialog.Content>
      </Dialog>

      {/*
      <Button
        className="fixed left-[40%] top-4 z-[5000000]"
        uppercase
        onClick={() => {
          setProfile(user)
        }}
      >
        Dev Only: Impersonate @{user.username}
      </Button>
      */}

      <div className="flex items-center gap-x-4">
        <Avatar
          className={`
            size-24
            lg:size-32
          `}
          src={user.avatar || makeBlockie(user?.ethereum_address || "0x")}
        />
        <div className="flex-1">
          <p
            className={`
              text-2xl
              lg:text-3xl
            `}
          >
            {user.fullname || user.username}
          </p>
          <div className="text-sm">
            {user.username && <span className="mr-2">@{user.username}</span>}
            <EthAddress address={user.ethereum_address} />
          </div>
          <nav
            className={`
              mt-2 flex gap-x-2
              lg:mt-3
            `}
          >
            {account?.ethereum_address?.toLowerCase() ===
            user.ethereum_address.toLowerCase() ? (
              <Button
                uppercase
                kind="primary"
                onClick={() => {
                  router.push("/account/edit-profile")
                }}
              >
                Edit Profile
              </Button>
            ) : (
              <>
                <FollowOrUnfollowButton user={user} />
                <DropdownMenu contentClassName="w-4 lg:w-12">
                  <DropdownMenu.Button onClick={handleClickMute}>
                    {isMuted ? "Unmute" : "Mute"}
                  </DropdownMenu.Button>
                </DropdownMenu>
              </>
            )}
          </nav>
        </div>
      </div>
    </>
  )
}
