import Link from "next/link"
import { ArtworkMedia } from "./base"
import { Icon } from "@/ui/icon"
import { AvatarProfile } from "@/ui/avatarProfile"
import { ArtworkUser } from "../shared/ArtworkCard"
import { Avatar } from "@/ui/avatar"
import { Button } from "@/ui/button"
import { SeriesCardType } from "@/utils/algolia"

const SeriesCard = ({ series }: { series: SeriesCardType }) => {
  const seriesLink = `/series/${series.token_contract_address}`

  return (
    <div className="group" key={series.token_contract_address}>
      <ArtworkMedia
        href={seriesLink}
        className={`
          object-cover
          group:hover:scale-110
        `}
        image={series.collection.image ?? ""}
      />
      <Link
        className={`
          mt-4 flex items-center gap-2 text-lg
          hover:underline
        `}
        href={seriesLink}
      >
        <Icon name="series3" invertOnDark />
        <p className="w-full overflow-hidden text-ellipsis text-nowrap">
          {series.collection.name}
        </p>
      </Link>
      <div className="flex h-14 items-center gap-8 border-b border-sr-border-tertiary py-3">
        <AvatarProfile size="sm" user={series.creator} />

        {series.collectors && series.collectors.length ? (
          <ArtworkUser
            counter="left"
            size="sm"
            counterValue={series.collectors_count}
            users={series.collectors}
          />
        ) : series.collectors_count && series.collectors_count > 0 ? (
          <div className="flex items-center gap-x-2 text-sm">
            {series.collectors_count} <Avatar src="" className="size-7" />
          </div>
        ) : null}
      </div>
      <div className="flex items-center gap-2 py-3">
        <Link href={seriesLink}>
          <Button
            kind="secondary"
            size="sm"
            uppercase
            className="text-sr-primary"
          >
            {series.floor.amount ? (
              <>
                {series.floor.amount.toFixed(2)} {series.floor.currency}
              </>
            ) : (
              "View"
            )}
          </Button>
        </Link>
        {series.has_live_auction && (
          <span className="relative flex size-3 items-center justify-center">
            <span
              className={`
                absolute inline-flex h-full w-full animate-ping rounded-full bg-black opacity-60
                dark:bg-white
              `}
            ></span>
            <span
              className={`
                relative inline-flex size-2 rounded-full bg-black
                dark:bg-white
              `}
            ></span>
          </span>
        )}
        <p className="text-sm text-sr-text-secondary">
          {series.count.artworks} Artworks
        </p>
      </div>
    </div>
  )
}

export default SeriesCard
