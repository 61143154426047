import GridCard from "@/components/cards/GridCard"
import { Erc721TokenExtended } from "@/components/cards/base"
import { trpc } from "@/trpc/client"
import { cn } from "@/utils/cn"

const HomepageCustomSection = (): JSX.Element => {
  const { data, isLoading } = trpc.homepage.getCustomSection.useQuery()

  if ((data && "empty" in data) || data?.visible === false) {
    return <></>
  }

  const gridColumns =
    data?.nfts && data.nfts.length >= 4
      ? "grid-cols-1 lg:grid-cols-4"
      : "grid-cols-1 lg:grid-cols-3"

  return (
    <section className="py-12">
      <h2
        className={`
          flex items-center text-xl text-sr-text-primary
          lg:text-3xl
        `}
      >
        {data?.title}
      </h2>
      <h3
        className={`
          max-w-[750px] text-sm text-sr-text-secondary
          lg:text-base
        `}
      >
        {data?.subtitle}
      </h3>
      {isLoading ? (
        <div
          className={`
            grid grid-cols-1 gap-8 pt-6
            lg:grid-cols-3 lg:pt-8
          `}
        >
          <div className="h-48 w-full bg-sr-ghost animate-pulse" />
          <div className="h-48 w-full bg-sr-ghost animate-pulse" />
          <div className="h-48 w-full bg-sr-ghost animate-pulse" />
        </div>
      ) : (
        <div className={cn("grid gap-8 pt-8", gridColumns)}>
          {data?.nfts.map((artwork) => (
            <div key={artwork.universal_token_id}>
              <GridCard
                kind="showcase"
                artwork={artwork as Erc721TokenExtended}
              />
            </div>
          ))}
        </div>
      )}
    </section>
  )
}

export default HomepageCustomSection
