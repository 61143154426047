import { trpc } from "@/trpc/client"
import { Button } from "@/ui/button"
import { Icon } from "@/ui/icon"
import { generateImgixUrl } from "@/utils/imgix"
import { motion, AnimatePresence } from "framer-motion"
import Image from "next/image"

type ItemArticle = {
  title: string
  timestamp: string
  image: string
  link: string
}

const ItemSkeleton = (): JSX.Element => {
  return (
    <article className="group flex flex-col">
      <div
        className={`
          h-64 animate-pulse rounded-md bg-sr-200
          dim:bg-sr-700
        `}
      />
      <h3 className="mb-1 mt-4 animate-pulse text-sr-text-primary">
        <div
          className={`
            h-6 w-3/4 rounded-md bg-sr-200
            dim:bg-sr-700
          `}
        />
      </h3>
      <div className="animate-pulse text-sm text-sr-text-tertiary">
        <div
          className={`
            h-4 w-1/2 rounded-md bg-sr-200
            dim:bg-sr-700
          `}
        />
      </div>
    </article>
  )
}

const ItemArticle = ({
  title,
  timestamp,
  image,
  link,
}: ItemArticle): JSX.Element => {
  return (
    <article
      className={`
        group flex items-center gap-4
        lg:flex-col lg:items-start
      `}
    >
      <div
        className={`
          relative aspect-square w-32 overflow-hidden rounded-md bg-sr-bg-tertiary
          lg:aspect-video lg:w-full
        `}
      >
        <a href={link} className="absolute inset-0" target="_blank">
          <Image
            src={
              image
                ? generateImgixUrl(image, {
                    width: "600",
                    height: null,
                    fm: "avif",
                    blur: null,
                    fit: "clip",
                    quality: "100",
                    video: false,
                    name: "editorial",
                    auto: "compress",
                  })
                : ""
            }
            alt={title}
            className={`
              duration-300
              group-hover:scale-110
            `}
            layout="fill"
            objectFit="cover"
          />
        </a>
      </div>
      <div className="flex flex-col">
        <h3 className="mb-1 text-sr-text-primary">
          <a
            href={link}
            className={`
              hover:underline
              lg:text-lg
            `}
            target="_blank"
          >
            {title}
          </a>
        </h3>
        <p className="text-sm text-sr-text-tertiary">{timestamp}</p>
      </div>
    </article>
  )
}

const HomepageEditorialFeatures = (): JSX.Element => {
  const { data, isLoading } = trpc.homepage.getEditorialFeatures.useQuery()

  return (
    <section className="py-12">
      <h2
        className={`
          flex items-center justify-between text-xl text-sr-text-primary
          lg:text-3xl
        `}
      >
        {`Don't Miss`}
        <a href="/explore">
          <Button kind="tertiarty" size="sm" uppercase>
            <span
              className={`
                hidden
                lg:block
              `}
            >
              See All
            </span>{" "}
            <Icon name="arrowRight" className="lg:hidden" invertOnDark />
          </Button>
        </a>
      </h2>
      <h3
        className={`
          text-sm text-sr-text-secondary
          lg:text-base
        `}
      >
        Exhibitions, projects and special releases.
      </h3>

      <AnimatePresence mode="wait">
        {isLoading ? (
          <motion.div
            key={"skeleton"}
            exit={{ opacity: 0 }}
            className={`
              grid grid-cols-1 gap-8 pt-6
              lg:grid-cols-3 lg:pt-8
            `}
          >
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
          </motion.div>
        ) : (
          <motion.div
            key={"editorial"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={`
              grid grid-cols-1 gap-3 pt-8
              lg:grid-cols-3 lg:gap-8
            `}
          >
            {data?.map((post) => (
              <ItemArticle
                key={post.id}
                title={post.name}
                timestamp={post.created}
                image={post.image}
                link={post.url}
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  )
}

export default HomepageEditorialFeatures
