import Link from "next/link"
import UserProfileDialog from "../shared/UserProfileDialog"
import { ArtworkAuthorAvatar } from "../shared/ArtworkCard"
import { cn } from "@/utils/cn"
import { Erc721Owner, User } from "@/graphql/generated/apollo/graphql"
import { Artist } from "@/server/lib/series/series.types"
import { AvatarProfile } from "@/ui/avatarProfile"

function isUserWithUsername(user: any): user is User {
  return user && typeof user === "object" && "username" in user
}
export const UserList = ({
  title = null,
  className,
  users,
  ethAddress,
  showUsername = true,
  count = null,
  limit = 3,
  counter = "default",
  onCounterClick,
  justify,
}: {
  title?: string | null
  className?: string
  users?: User | Artist | Erc721Owner | User[] | Artist[] | Erc721Owner[]
  ethAddress?: string
  showUsername?: boolean
  count?: number | null
  limit?: number
  counter?: "left" | "default" | "none"
  isArtist?: boolean
  onCounterClick?: () => void
  justify?: string
}): JSX.Element => {
  const userList = Array.isArray(users) ? users : [users]

  return (
    <div className={cn("flex flex-col gap-1", className)}>
      {title && (
        <div
          className={`
            mt-1 text-sm uppercase tracking-wide text-typography-grey-2
            md:
          `}
        >
          {title}
        </div>
      )}
      {!users || (Array.isArray(users) && users.length === 0) ? (
        <div
          className={`
            flex h-8 items-center
            ${justify}
          `}
        >
          {ethAddress ? <AvatarProfile size="sm" address={ethAddress} /> : "-"}
        </div>
      ) : (
        <div
          className={`
            flex items-center
            ${justify}
          `}
        >
          {counter == "left" && (
            <button className="mr-2" onClick={onCounterClick}>
              {count ?? userList.length}
            </button>
          )}
          {userList.slice(0, limit).map((user, index) => (
            <div
              key={`avatar-user-${index}-${
                user && "ethereum_address" in user
                  ? user.ethereum_address
                  : ethAddress
              }`}
              className={cn("flex items-center", index > 0 && "-ml-1")}
            >
              <UserProfileDialog
                sideOffset={0}
                ethAddress={
                  user && "ethereum_address" in user
                    ? user.ethereum_address || ""
                    : ethAddress || ""
                }
              >
                <div className="py-0.5">
                  <ArtworkAuthorAvatar
                    key={`avatar-artwork-${index}-${
                      user && "ethereum_address" in user
                        ? user.ethereum_address || ethAddress
                        : ethAddress || ""
                    }`}
                    user={isUserWithUsername(user) ? user : null}
                  />
                </div>
                {userList.length === 1 &&
                  showUsername &&
                  isUserWithUsername(user) && (
                    <Link href={`/${user.username}`}>
                      <span className="ml-2">{user.username}</span>
                    </Link>
                  )}
              </UserProfileDialog>
            </div>
          ))}
          {counter == "default" && userList.length > limit && (
            <button
              onClick={onCounterClick}
              className={`
                flex h-8 w-8 items-center justify-center rounded-full border
                dim:border-sr-border-tertiary
              `}
            >
              +{userList.length - limit}
            </button>
          )}
        </div>
      )}
    </div>
  )
}
