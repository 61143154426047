import GridCard from "@/components/cards/GridCard"
import { Erc721TokenExtended } from "@/components/cards/base"
import { trpc } from "@/trpc/client"
import { cn } from "@/utils/cn"
import { motion } from "framer-motion"

const HomepageFeaturedArtworks = (): JSX.Element => {
  const { data, isLoading } = trpc.homepage.getFeaturedSeriesSection.useQuery()

  const gridColumns =
    data && data.length >= 4
      ? "grid-cols-1 md:grid-cols-2 xl:grid-cols-4"
      : "grid-cols-1 lg:grid-cols-3"

  return (
    <section className="py-12">
      <h2
        className={`
          flex items-center text-xl text-sr-text-primary
          lg:text-3xl
        `}
      >
        Featured
      </h2>
      <h3
        className={`
          text-sm text-sr-text-secondary
          lg:text-base
        `}
      >
        Curated by the SuperRare team
      </h3>
      {isLoading ? (
        <motion.div
          key="loading"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="grid grid-cols-3 gap-8 pt-8"
        >
          <div
            className={`
              h-96 w-full animate-pulse rounded-md bg-sr-200
              dim:bg-sr-700
            `}
          />
          <div
            className={`
              h-96 w-full animate-pulse rounded-md bg-sr-200
              dim:bg-sr-700
            `}
          />
          <div
            className={`
              h-96 w-full animate-pulse rounded-md bg-sr-200
              dim:bg-sr-700
            `}
          />
        </motion.div>
      ) : (
        <motion.div
          key="loaded"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={cn("grid gap-8 pt-8", gridColumns)}
        >
          {data?.map((artwork) => (
            <div className="w-full" key={artwork.universal_token_id}>
              <GridCard
                kind="showcase"
                artwork={artwork as Erc721TokenExtended}
              />
            </div>
          ))}
        </motion.div>
      )}
    </section>
  )
}

export default HomepageFeaturedArtworks
