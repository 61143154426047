import { Icon, IconType } from "@/ui/icon"
import React, { HTMLAttributeAnchorTarget } from "react"

type HelpBlock = {
  icon: IconType
  href: string
  title: string
  description: string
  target?: HTMLAttributeAnchorTarget
  onClick?: () => void
}

const HelpBlock: React.FC<HelpBlock> = ({
  icon,
  href,
  title,
  description,
  target = "_self",
  onClick,
}) => {
  const Wrapper = ({
    children,
    href,
    target,
    onClick,
  }: {
    href: string
    target?: HTMLAttributeAnchorTarget
    onClick?: () => void
    children: React.ReactNode
  }) => {
    if (onClick) {
      return (
        <button
          className={`
            flex w-full flex-col rounded-md border border-sr-border-tertiary p-4 duration-300
            hover:border-sr-border-primary hover:dim:border-sr-600
          `}
          onClick={onClick}
        >
          {children}
        </button>
      )
    }
    return (
      <a
        className={`
          flex w-full flex-col rounded-md border border-sr-border-tertiary p-4 duration-300
          hover:border-sr-border-primary hover:dim:border-sr-600
        `}
        href={href}
        target={target}
        onClick={onClick}
      >
        {children}
      </a>
    )
  }
  return (
    <Wrapper href={href} target={target} onClick={onClick}>
      <h3
        className={`
          mb-2 flex items-center gap-2 text-lg
          lg:text-2xl
        `}
      >
        <Icon
          name={icon}
          invertOnDark
          className={`
            size-4
            lg:size-5
          `}
        />
        {title}
      </h3>
      <p
        className={`
          text-sm text-sr-text-secondary
          lg:text-base
        `}
      >
        {description}
      </p>
    </Wrapper>
  )
}

const HomepageStatsHelp = (): JSX.Element => {
  const stats = [
    {
      stats: "38,000",
      description: "Artworks Collected",
    },
    {
      stats: "$182m",
      description: "Earned by artists",
    },
    {
      stats: "$592m",
      description: "NFT Art Market Cap",
    },
    {
      stats: "$9m",
      description: "In Artist Royalties",
    },
  ]
  const openIntercom = () => {
    ;(window as any).Intercom("show")
  }
  return (
    <section
      className={`
        border-t border-sr-border-tertiary pt-12
        lg:py-12
      `}
    >
      <h2
        className={`
          text-3xl text-sr-text-primary
          lg:text-[64px]
        `}
      >
        The art market onchain.
      </h2>
      <ul
        className={`
          grid grid-cols-2 gap-4 pb-14 pt-8
          lg:grid-cols-4
        `}
      >
        {stats.map((stat) => (
          <li
            key={stat.stats}
            className={`
              text-base text-sr-text-primary
              lg:pr-36 lg:text-lg
            `}
          >
            <h3>{stat.stats}</h3>
            <p>{stat.description}</p>
          </li>
        ))}
      </ul>
      <nav
        className={`
          grid grid-cols-1 gap-2
          lg:grid-cols-2 lg:gap-4
          xl:grid-cols-4 xl:gap-8
        `}
      >
        <HelpBlock
          icon="help"
          href="https://help.superrare.com/"
          title="Help Center"
          description="Advice and answers from the SuperRare Team"
          target="_blank"
        />
        <HelpBlock
          icon="discord"
          href="https://discord.gg/superrare"
          title="Join our Discord"
          description="Over 30k members and counting"
        />
        <HelpBlock
          icon="x"
          href="https://twitter.com/SuperRare"
          title="Follow SuperRare"
          description="Product news, drops, Twitter Spaces and more"
        />
        <HelpBlock
          icon="intercom"
          href=""
          title="Chat Support"
          description="Hi there 👋 How can we help?"
          onClick={openIntercom}
        />
      </nav>
    </section>
  )
}

export default HomepageStatsHelp
