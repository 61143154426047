import ShowcaseCard from "@/components/cards/ShowcaseCard"
import { trpc } from "@/trpc/client"
import { Erc721TokenExtended } from "@/components/cards/base"
import { motion } from "framer-motion"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper/modules"

import "swiper/css"
import "swiper/css/pagination"
import { cn } from "@/utils/cn"

const HomepageHero = (): JSX.Element => {
  const { data, isLoading } = trpc.homepage.getFeaturedSection.useQuery()
  if (isLoading) {
    return (
      <motion.div
        key={"loading"}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="flex justify-center"
      >
        <div className="flex w-[480px] flex-col justify-center gap-3">
          <div
            className={`
              h-[680px] animate-pulse rounded-md bg-sr-200
              dim:bg-sr-700
            `}
          ></div>
          <div
            className={`
              h-6 w-80 animate-pulse rounded-md bg-sr-200
              dim:bg-sr-700
            `}
          ></div>
          <div
            className={`
              h-6 w-32 animate-pulse rounded-md bg-sr-200
              dim:bg-sr-700
            `}
          ></div>
          <div
            className={`
              h-[1px] w-full animate-pulse rounded-md bg-sr-200
              dim:bg-sr-700
            `}
          ></div>
          <div
            className={`
              h-6 w-40 animate-pulse rounded-md bg-sr-200
              dim:bg-sr-700
            `}
          ></div>
        </div>
      </motion.div>
    )
  }

  if (!data || data.length === 0) {
    return <></>
  }

  return (
    <motion.div
      key={"loaded"}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={`
        flex flex-col justify-center overflow-x-hidden
        lg:flex-row
      `}
    >
      <Swiper
        className=""
        spaceBetween={0}
        slidesPerView={1}
        autoHeight={true}
        enabled={data.length > 1}
        breakpoints={{
          1024: {
            enabled: false,
            spaceBetween: 32,
          },
        }}
        pagination={{ clickable: true, el: ".swiper-pagination" }}
        modules={[Pagination]}
      >
        {data.map((item, index) => (
          <SwiperSlide
            key={index}
            className={cn(
              `
                !w-full items-end
                lg:!w-auto
              `,
              data.length === 2
                ? "lg:!w-1/2"
                : data.length === 3
                  ? "lg:!w-1/3"
                  : ""
            )}
          >
            <ShowcaseCard
              detailsPosition={data.length > 1 ? "bottom" : "default"}
              artwork={item as Erc721TokenExtended}
              totalArtworks={data.length}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        className={`
          relative pt-10
          lg:hidden
        `}
      >
        <div className="swiper-pagination"></div>
      </div>
    </motion.div>
  )
}

export default HomepageHero
