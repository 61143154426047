import { create } from "zustand"

export type modalTypes = "complete-profile" | "connect-wallet" | null
export type infoAction = "mute" | "generic" | "new-art"

type InfoModal = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  type: modalTypes
  setType: (type: modalTypes) => void
  action: infoAction
  setAction: (action: infoAction) => void
}
export const useModal = create<InfoModal>((set, get) => ({
  isOpen: false,
  setIsOpen: () => set({ isOpen: !get().isOpen }),
  type: null,
  setType: (type) => set({ type }),
  action: "generic",
  setAction: (action) => set({ action }),
}))
