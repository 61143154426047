import useArtwork from "./useArtwork"
import {
  ArtworkAuction,
  ArtworkDetails,
  ArtworkMedia,
  ArtworkSales,
  ArtworkShowcaseSales,
  ArtworkUpcomingAuction,
  Erc721TokenExtended,
} from "./base"
import { motion } from "framer-motion"
import { useFavoritesStore } from "@/stores/favorites"
import { cn } from "@/utils/cn"
import { getMuxUrl } from "@/utils/mux"
import { chainIdToSlug } from "@/utils/chains"
import { CHAIN_ID } from "@/constants/environment"
import { generateImgixUrl } from "@/utils/imgix"

interface GridCardProps {
  kind: "default" | "basic" | "showcase"
  page?: string
  favorites?: boolean
  showPinning?: boolean
  artwork: Erc721TokenExtended
  handleRemoveArtwork?: (artwork: Erc721TokenExtended) => void
}

const GridCard = ({
  kind,
  artwork,
  page,
  favorites,
  showPinning,
}: GridCardProps): JSX.Element => {
  const { isAuction, isRunningAuction, isUpcomingAuction, isUnsettledAuction } =
    useArtwork(artwork)
  const { isWaitingRemoval } = useFavoritesStore()
  const image =
    artwork.nft_image?.image_w800 ??
    (artwork.erc721_metadata?.metadata.image &&
      generateImgixUrl(artwork.erc721_metadata?.metadata.image, {
        width: "1200",
        height: null,
        fm: "avif",
        blur: null,
        fit: "clip",
        quality: "100",
        video: false,
        name: "homepage_hero",
        auto: "compress",
      })) ??
    undefined

  const video = artwork.nft_image?.video_clipped_mux_playback_id
    ? getMuxUrl(artwork.nft_image.video_clipped_mux_playback_id, "medium")
    : undefined

  const imageBlurred = artwork.nft_image?.image_blurred_w390 ?? undefined

  return (
    <motion.article
      className={cn("group flex flex-col", {
        "pointer-events-none opacity-25 duration-300":
          favorites && isWaitingRemoval.includes(artwork.token_id),
      })}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <ArtworkMedia
        href={`/artwork/${chainIdToSlug(CHAIN_ID) || "eth"}/${artwork.contract_address}/${artwork?.token_id}`}
        image={image}
        image_blurred={imageBlurred}
        video={video}
        page={page}
      ></ArtworkMedia>

      <ArtworkDetails
        artwork={artwork}
        favorites={favorites}
        showPinning={showPinning}
      />
      {isAuction && isRunningAuction ? (
        <ArtworkAuction artwork={artwork} kind={kind} auction={isAuction} />
      ) : isAuction && isUpcomingAuction && kind === "showcase" ? (
        <ArtworkShowcaseSales artwork={artwork} />
      ) : isAuction && isUpcomingAuction ? (
        <ArtworkUpcomingAuction
          artwork={artwork}
          kind={kind}
          auction={isAuction}
        />
      ) : kind === "showcase" ? (
        <ArtworkShowcaseSales artwork={artwork} />
      ) : (
        <ArtworkSales
          artwork={artwork}
          reserveAuction={isUnsettledAuction ? undefined : isAuction}
        />
      )}
    </motion.article>
  )
}

export default GridCard
