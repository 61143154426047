import { useThemeStore } from "@/stores/theme"
import { trpc } from "@/trpc/client"
import { Icon } from "@/ui/icon"
import { Cross1Icon } from "@radix-ui/react-icons"
import { motion } from "framer-motion"
import parse from "html-react-parser"

type BannersProps = {
  title: string
  description: string
  link: string
  media: string
  isVideo: boolean
}

enum BannerType {
  Basic = "Basic",
  Large = "Large",
  Takeover = "Takeover",
}

const BannerTakeover = ({ title }: BannersProps): JSX.Element => {
  const { setNoticeBarDismissed } = useThemeStore()

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      className={`
        bg-black
        dark:bg-white
        dim:bg-sr-700
      `}
    >
      <div className="container relative flex h-[calc(100vh-67px)] flex-col items-center gap-24 text-sr-bg-primary">
        <button
          onClick={() => setNoticeBarDismissed(true)}
          className="absolute right-6 top-5 rounded-md border px-3 py-2 text-white"
        >
          <Cross1Icon className="inline" />
        </button>
        <div className="aspect-video h-[70vh]"></div>
        <div
          className={`
            mb-8 mt-auto flex w-full items-center justify-between rounded-md bg-sr-bg-tertiary p-3 text-sr-text-primary
          `}
        >
          {title && <p className="text-xl">{title}</p>}
          <button
            className={`
              rounded-md border px-6 py-2 text-xl
              dark:border-black
              dim:border-white
            `}
          >
            <Icon
              name="arrowRight"
              className={`
                size-3 invert
                dark:invert-0
                dim:invert
              `}
            />
          </button>
        </div>
      </div>
    </motion.div>
  )
}

const BannerLarge = ({
  title,
  description,
  media,
}: BannersProps): JSX.Element => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      className={`
        bg-black
        dark:bg-white
        dim:bg-sr-700
      `}
    >
      <div className="container flex h-[320px] items-center gap-24 text-sr-bg-primary">
        <div
          className="h-[276px] w-8/12 rounded-md bg-contain bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${media})` }}
        ></div>
        <div
          className={`
            flex w-4/12 flex-col items-start
            dim:text-white
          `}
        >
          <p className="text-3xl">{title}</p>
          <p>{parse(description)}</p>
          <button
            className={`
              mt-3 rounded-md border px-6 py-2 text-xl
              dark:border-black
              dim:border-white
            `}
          >
            <Icon
              name="arrowRight"
              className={`
                size-4 invert
                dark:invert-0
                dim:invert
              `}
            />
          </button>
        </div>
      </div>
    </motion.div>
  )
}

const BannerBasic = ({ description }: BannersProps): JSX.Element => {
  const { setNoticeBarDismissed } = useThemeStore()

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      className={`
        z-10 -mt-[2px] flex min-h-[46px] items-center justify-center bg-sr-bg-quaternary content-with-urls border-y
        border-sr-border-primary py-2 text-center text-sr-text-primary
        lg:h-[52px] lg:py-0 lg:pt-[2px]
      `}
    >
      <p
        className={`
          ml-auto max-w-[320px] text-sm
          lg:max-w-4xl lg:text-base
        `}
      >
        {parse(description)}
      </p>
      <button
        onClick={() => setNoticeBarDismissed(true)}
        className={`
          ml-auto mr-2 flex size-7 items-center justify-center rounded-md bg-sr-bg-tertiary duration-300
          dark:hover:bg-sr-700
          hover:bg-sr-400
          lg:size-8
        `}
      >
        <Cross1Icon className="inline" />
      </button>
    </motion.div>
  )
}

const NoticeBar = (): JSX.Element | null => {
  const { data } = trpc.homepage.noticeBar.useQuery()
  const { noticeBarDismissed, setNoticeBarDismissed } = useThemeStore()

  if (!data) return null
  if (!data.visible) return null
  if (noticeBarDismissed && data?.type !== BannerType.Takeover) return null

  if (data?.type === BannerType.Takeover && noticeBarDismissed) {
    return (
      <div className="container">
        <div
          className={`
            mt-4 flex w-full items-center justify-between rounded-md bg-sr-bg-tertiary p-3 text-sr-text-primary
          `}
        >
          {data?.title && <p className="text-xl">{data.title}</p>}
          <button
            className={`
              rounded-md border px-6 py-2 text-xl
              dark:border-black
              dim:border-white
            `}
            onClick={() => setNoticeBarDismissed(false)}
          >
            <Icon
              name="arrowRight"
              className={`
                size-3 invert
                dark:invert-0
                dim:invert
              `}
            />
          </button>
        </div>
      </div>
    )
  }

  switch (data.type) {
    case BannerType.Basic:
      return (
        <BannerBasic
          title={data.title}
          description={data.description}
          link={data.link}
          media={data.media}
          isVideo={data.isVideo}
        />
      )
    case BannerType.Large:
      return (
        <BannerLarge
          title={data.title}
          description={data.description}
          link={data.link}
          media={data.media}
          isVideo={data.isVideo}
        />
      )
    case BannerType.Takeover:
      return (
        <BannerTakeover
          title={data.title}
          description={data.description}
          link={data.link}
          media={data.media}
          isVideo={data.isVideo}
        />
      )
    default:
      return null
  }
}

export default NoticeBar
